import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Collapse,
  Button
} from "react-bootstrap";
import Layout from "../components/Layout";
import PortfolioViewer from "../components/PortfolioViewer";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Hero from "../components/Hero";
import Markdown from "react-markdown";
import ShowMoreIcon from "../components/ShowMoreIcon";

export const IndexPageTemplate = ({
  content,
  whoWhyWhen,
  getInTouch,
  caseStudies,
  logos,
  recognition,
  workedWith
}) => {
  const [open, setOpen] = useState(false);
  const [openWhoWhyWhen, openWhoWhyWhenOpen] = useState(false);
  return (
    <div>
      <Hero />
      <section className="section section-who-what-why">
        <Container className="py-5">
          <Row>
            {whoWhyWhen.map(item => (
              <Col lg={4} key={item.heading} className="mb-3 mb-lg-0">
                {item.heading && <h2 className="h4">{item.heading}</h2>}
                <Markdown source={item.text} />
                {item.textMore && (
                  <>
                    <Collapse in={openWhoWhyWhen}>
                      <div id="who-why-when-collapse">
                        <Markdown source={item.textMore} />
                      </div>
                    </Collapse>
                    <Button
                      className="mx-auto mb-3 collapse-arrow"
                      block
                      variant="outline-primary"
                      onClick={() => openWhoWhyWhenOpen(!openWhoWhyWhen)}
                      aria-controls="who-why-when-collapse"
                      aria-expanded={openWhoWhyWhen}
                    >
                      <ShowMoreIcon />
                      {openWhoWhyWhen ? "Hide" : "Show more"}
                    </Button>
                  </>
                )}
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="section section-case-studies">
        <Container>
          <Row>
            <Col className="text-center mb-4">
              <h2>{caseStudies.title}</h2>
              <p className="h5 text-uppercase text-muted">{caseStudies.text}</p>
            </Col>
          </Row>
        </Container>
        <PortfolioViewer />
      </section>
      <section className="section section-recognition">
        <Container>
          <div className="text-center mb-4">
            <h2>{recognition.title}</h2>
            {recognition.paragraphs.map((paragraph, idx) => (
              <p className="lead text-muted" key={paragraph}>
                {paragraph}
              </p>
            ))}
          </div>
          <hr className="mb-5" />
          <div className="d-flex mb-3">
            <Button
              className="mx-auto mb-3 collapse-arrow"
              block
              variant="outline-primary"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <ShowMoreIcon />
              {open ? "Hide" : "Show"} Awards List
            </Button>
          </div>
          <Collapse in={open}>
            <div id="example-collapse-text">
              <div
                className="about-columns"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </Collapse>
        </Container>
      </section>
      <section className="section section-worked-with">
        <Container>
          <Row>
            <Col className="text-center mb-4">
              <h2>{workedWith.title}</h2>
              {workedWith.paragraphs.map((paragraph, idx) => (
                <p className="lead text-muted" key={paragraph}>
                  {paragraph}
                </p>
              ))}
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            {logos.map(logo => (
              <Col
                xs={6}
                md={4}
                lg={3}
                xl={3}
                className="p-4 logo"
                key={logo.alt}
              >
                <PreviewCompatibleImage imageInfo={logo} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section id="contact" className="text-center my-4">
        <div className="text-center mb-4">
          <h2>Get In Touch</h2>
        </div>
        <ListGroup className="contact-details">
          {getInTouch.map((item, idx) => (
            <ListGroup.Item key={item.text} className="col-md-6">
              {item.title && <strong>{item.title}: </strong>}
              {item.link ? <a href={item.link}>{item.text}</a> : item.text}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  whoWhyWhen: PropTypes.array,
  getInTouch: PropTypes.array,
  logos: PropTypes.array,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { markdownRemark: post } = data;

  return (
    <Layout title={frontmatter.title} description={frontmatter.description}>
      <IndexPageTemplate
        content={post.html}
        slider={frontmatter.slider}
        whoWhyWhen={frontmatter.whoWhyWhen}
        getInTouch={frontmatter.getInTouch}
        about={frontmatter.about}
        caseStudies={frontmatter.caseStudies}
        recognition={frontmatter.recognition}
        workedWith={frontmatter.workedWith}
        logos={frontmatter.logos}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        whoWhyWhen {
          heading
          text
          textMore
        }
        getInTouch {
          text
          link
        }
        caseStudies {
          title
          text
        }
        workedWith {
          title
          paragraphs
        }
        recognition {
          title
          paragraphs
        }
        slider {
          alt
          video
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        logos {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  }
`;
