import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { Container, Row, Col, Nav } from "react-bootstrap";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import PortfolioViewerFilter from "./PortfolioViewerFilter";

class PortfolioViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilter: null
    };
    this.setFilter = this.setFilter.bind(this);
  }

  setFilter(e, activeFilter) {
    e.preventDefault();
    this.setState({ activeFilter });
  }

  render() {
    const { activeFilter } = this.state;
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const filters = ["Branding", "Campaign"];

    return (
      <>
        {/* <div className="mb-4 bg-dark text-white py-3">
          <Container>
            <Row>
              <Col>
                <PortfolioViewerFilter
                  setFilter={this.setFilter}
                  activeFilter={activeFilter}
                  filters={filters}
                />
              </Col>
            </Row>
          </Container>
        </div> */}
        <Container>
          <Row className="mb-4">
            {posts &&
              posts.map(
                ({ node: post }) =>
                  (!activeFilter ||
                    (activeFilter &&
                      post.frontmatter.tags.includes(activeFilter))) && (
                    <Col md="6" lg="4" xl="3" key={post.id} className="mb-4">
                      <div className="portfolio-viewer">
                        <article
                          className={`portfolio-item${
                            post.frontmatter.imgContain ? " img-contain" : ""
                          }`}
                        >
                          {post.frontmatter.featuredimage ? (
                            <div
                              className={`featured-thumbnail ${
                                post.frontmatter.imgBackground
                                  ? ` bg-${post.frontmatter.imgBackground}`
                                  : ""
                              }`}
                            >
                              <PreviewCompatibleImage
                                imageInfo={{
                                  image: post.frontmatter.featuredimage,
                                  alt: `featured image thumbnail for post ${
                                    post.title
                                  }`
                                }}
                              />
                            </div>
                          ) : null}
                          <Link className="overlay" to={post.fields.slug}>
                            <span className="overlay-content">
                              <span className="title">
                                {post.frontmatter.title}
                              </span>
                              <span className="tags">
                                {post.frontmatter.description}
                              </span>
                            </span>
                          </Link>
                        </article>
                      </div>
                    </Col>
                  )
              )}
          </Row>
        </Container>
      </>
    );
  }
}

PortfolioViewer.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query PortfolioViewerQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }
          filter: { frontmatter: { templateKey: { eq: "portfolio-item" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                tags
                date(formatString: "MMMM DD, YYYY")
                description
                imgContain
                imgBackground
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PortfolioViewer data={data} count={count} />}
  />
);
