import React from "react";
import { Container } from "react-bootstrap";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content hide-from-screen">
        <Container className="d-flex align-items-center">
          <div>
            <h1 className="font-montserrat">Andrew Hawley</h1>
            <p className="font-montserrat">
              Experienced creative leader. Pragmatic problem solver.
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
